<template>
  <div class="about2">
      <div class="aboutBox ">
            <div class="content_w">
                <div class="aboutBox_title">{{content.title}}</div>
                <div class="aboutBox_Div">
                    <img :src="content.pic" class="zzjgImg" alt="">
                </div>
            </div>
      </div>
  </div>
</template>

<script>
 
export default {
  name: "about2",
  components: {
  },
  props:['content'],
  data() {
    return {
     
        
    };
  },
  methods:{
      NavSwitch(index){
          this.navactive=index
      }
  },
};
</script>
<style scoped>

 
.aboutBox{
    padding: 1rem 0;
    background-color: #ffffff;
}
.aboutBox2{
    padding: 1rem 0;
    background-color: #f5f5f5;
}
.aboutBox_title{
    font-size: 0.4rem;
    color: #333333;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
}

.aboutBox_Div{
    display: flex;
    align-items: flex-start;
}

.zzjgImg{
    width: 80%;
    display: block;
    margin: 0 auto;
}
@media screen and (max-width: 414px){
    .zzjgImg{
        width: 100%;
        display: block;
        margin: 0 auto;
    }
    .aboutBox{
       padding-top: .5rem;
    }
   .aboutBox_title{
       margin-bottom: .4rem;
   }
}
 
</style>