<template>
  <div class="">
  <div class="about1" id="about1"  v-if="content&&fieldShow">
      <div class="aboutBox ">
            <div class="content_w">
                <div class="aboutBox_title">{{content.content0.title}}</div>
                <div class="aboutBox_Div">
                    <div class="aboutBox_Div_left animate__animated animate__fadeInLeft ">
                        <div class="aboutBox_Div_left_title">{{content.content0.title3}}</div>
                        <div class="aboutBox_Div_left_text" v-for="(item,index) in content.content0.gai" :key="index">{{item}}</div>
                    </div>
                    <div class="aboutBox_Div_right animate__animated animate__fadeInUp button bz-buttonAnimat counterclockwise both ">
                        <div class="abouImg">
                            <img :src="content.content0.pic" style="width:100%" alt="">
                        </div>
                        <div class="innerCopy"></div>
                        <div class="inner"></div>
                        <div class="aboutBox_Div_right_tag">{{content.content0.title2}}</div>
                    </div>
                </div>
            </div>
      </div>
      <div class="aboutBox2 " id="gdjs">
            <div class="content_w" >
                <div class="aboutBox_title">{{content.content1.title}}</div>
                <div class="aboutBox_Div2 ">
                    <div class="aboutBox_Div_right2 animate__animated animate__fadeInLeft  button bz-buttonAnimat clockwise both ">
                          <div class="abouImg">
                            <img :src="content.content1.pic" style="width:100%" alt="">
                        </div>
                        <div class="innerCopy"></div>
                        <div class="inner"></div>
                        <div class="aboutBox_Div_right_tag2">{{content.content1.title2}}</div>
                    </div>
                    <div class="aboutBox_Div_left2 animate__animated animate__fadeInUp">
                        <div class="aboutBox_Div_left_title">{{content.content1.title3}}</div>
                        <div class="aboutBox_Div_left_text" v-for="(item,index) in content.content1.gai" :key="index">{{item}}</div>
                    </div>
                    
                </div>
            </div>
      </div>

      <div class="aboutBox ">
            <div class="content_w">
                <div class="aboutBox_title">{{content.content2.title}}</div>
                <div class="aboutBox_Div3 animate__animated animate__fadeInRight">
                    <div class="aboutBox_Div3_li" :class="index%2==0?'':'fumt'"  v-for="(item,index) in content.content3" :key="index">
                        <img :src="item.pic" alt="">
                        <div class="aboutBox_Div3_li_rightDiv "  :class="index%2==0?'aboutBox_Div3_li_rightDivL':''">{{item.title}}</div>
                        <div class="aboutBox_Div3_hoverDiv ">
                            <div class="aboutBox_Div3_hoverTitle">{{item.title}}</div>
                            <div class="aboutBox_Div3_hoverText">{{item.gai}}</div>
                        </div>
                    </div>
                </div>
            </div>
      </div>

      <div class="aboutBox2 ">
            <div class="content_w">
                <div class="aboutBox_title">{{content.content4.title}}</div>
                <div class="aboutBox_Div4 animate__animated animate__fadeInUpBig">
                    <div class="aboutBox_Div4_title">{{content.content4.title2}}</div>
                    <div class="aboutBox_Div4_text">{{content.content4.gai}}</div>
                    <div class="aboutBox_Div4_fzgh">
                        <img src="../../../static/img/about/fzgh.png"  alt="">
                        <div class="aboutBox_Div4_fzghDiv animate__animated animate__fadeInUpBig" :class="'aboutBox_Div4_fzghDiv'+(index+1)"  v-for="(item,index) in content.content5" :key="index">
                            <div>{{item.title}}</div>
                            <div>{{item.title2}}</div>
                        </div>
                    </div>
                </div>
            </div>
      </div>


      <div class="aboutBox "  id="hzhb">
            <div class="content_w">
                <div class="aboutBox_title">{{content.content6.title}}</div>
                <div class="aboutBox_Div5">
                     <div class="about_Div5_Div about_Div5_left">
                         <div class="about_Div5_left_NavList">
                             <div class="about_Div5_left_title"  >{{content.content7[0].title}}</div>
                         </div>
                         <div>
                             <swiper class="swiper-Box"  ref="mySwiper" :options="swiperOption"      >
                                <swiper-slide class="swiper-slide" v-for="(item,index) in content.content7[0].multiple_pic" :key="index">
                                    <div class="swiperDiv" >
                                        <div class="swiperDiv_li" v-for="(items,indexs) in item" :key='indexs'>
                                            <img :src="items" alt="">
                                        </div>
                                    </div>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </swiper> 
                         </div>
                         
                     </div>
                     <div class="about_Div5_Div about_Div5_right">
                         <div class="about_Div5_left_NavList">
                             <div class="about_Div5_left_title" :class="navactive==1?'navActive':''" @click="NavSwitch(1)">{{content.content7[1].title}}</div>
                             <div class="about_Div5_left_title" :class="navactive==2?'navActive':''" @click="NavSwitch(2)">{{content.content7[2].title}}</div>
                         </div>
                         <div v-show="navactive==1">
                             <swiper class="swiper-Box"  ref="mySwiper" :options="swiperOption2"     >
                                <swiper-slide class="swiper-slide" v-for="(item,index) in content.content7[1].multiple_pic" :key="index">
                                    <div class="swiperDiv" >
                                        <div class="swiperDiv_li" v-for="(items,indexs) in item" :key='indexs'>
                                            <img :src="items" alt="">
                                        </div>
                                    </div>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </swiper> 
                         </div>
                         <div v-show="navactive==2">
                             <swiper class="swiper-Box"  ref="mySwiper" :options="swiperOption3"     >
                                <swiper-slide class="swiper-slide" v-for="(item,index) in content.content7[2].multiple_pic" :key="index">
                                    <div class="swiperDiv" >
                                        <div class="swiperDiv_li" v-for="(items,indexs) in item" :key='indexs'>
                                            <img :src="items" alt="">
                                        </div>
                                    </div>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </swiper> 
                         </div>
                     </div>
                </div>
            </div>
      </div>
     
  </div>
  <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>

<script>
import '../../../static/css/lineAnimation.css';
export default {
  name: "about1",
  components: {
  },
  data() {
    return {
        fieldShow:false,
      navactive:1,
      navlist:[],
      navlist2:[],
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        // mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        // height: '455px', // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        spaceBetween : 20,
        updateOnImagesReady:true,
        loop: true,
        loopedSlides: 2,
        speed:1500,
        pagination: {
            el: '.swiper-pagination',
        },
        autoplay: {
            delay: 2000,
            disableOnInteraction:false
        },
        on:{
        }
      }, 
       swiperOption2: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        // mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        // height: '455px', // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        spaceBetween : 20,
        updateOnImagesReady:true,
        loop: true,
        loopedSlides: 2,
        speed:1500,
        pagination: {
            el: '.swiper-pagination',
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction:false
        },
        on:{
        }
      },
       swiperOption3: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        // mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        // height: '455px', // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        spaceBetween : 20,
        updateOnImagesReady:true,
        loop: true,
        loopedSlides: 2,
        speed:1500,
        pagination: {
            el: '.swiper-pagination',
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction:false
        },
        on:{
        }
      },
    };
  },
  props:['content'],

  created(){
  },
  watch:{
      $route(to,from){
          let that=this
			 that.$nextTick(function(){
                setTimeout(function(){
                    if(that.$route.query.selector){
                            that.fieldShow = that;
                            that.goAnchor(that.$route.query.selector);
                        }
                },1000)
            })
	},
  },
  mounted(){
      let that=this
      that.$nextTick(function(){
          setTimeout(function(){
               if(that.$route.query.selector){
                    that.fieldShow = that;
                    that.goAnchor(that.$route.query.selector);
                }
         },1000)
      })
      window.addEventListener("scroll", this.onScroll,true);
      if(this.inwidth > 768){
        setTimeout((res) => {
            this.fieldShow = true;
         }, 2500);
      }else{
          this.fieldShow = true;
      }
     
     
   },
  methods:{
        //锚点跳转
    goAnchor(selector) {
        let that=this
            let scrollTop = that.$el.querySelector('#'+selector).offsetTop;
            $('body').animate({scrollTop:scrollTop},1000);           
    },
       onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
		},
      NavSwitch(index){
          this.navactive=index
      },

     
  },
  beforeDestroy() {
    //清除监听
    let that=this
    window.removeEventListener("scroll", that.onScroll, true);
  }
};
</script>
<style >

 
.aboutBox{
    padding: 1rem 0;
    background-color: #ffffff;
}
.aboutBox2{
    padding: 1rem 0;
    background-color: #f5f5f5;
}
.aboutBox_title{
    font-size: 0.4rem;
    color: #333333;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
}

.aboutBox_Div{
    display: flex;
    align-items: flex-start;
}
.aboutBox_Div2{
    display: flex;
    align-items: flex-start;
}
.aboutBox_Div_left{
    width: 45%;
    margin-right: 0.7rem;
}
.aboutBox_Div_left_title{
    font-size: 0.3rem;
    color: #333333;
    font-weight: bold;
    margin-bottom: .3rem;
    /* margin-bottom: 0.3rem; */
}
.aboutBox_Div_left_text{
    font-size: 0.15rem;
    color: #333333;
    line-height: 0.26rem;
    /* text-indent: 0.3rem; */
    margin-top: 0.15rem;

    /* margin-bottom: 0.1rem; */
}
.innerCopy{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 6px solid #ebebeb;
    box-sizing: border-box;
}
.aboutBox_Div_right{
    flex: 1;
    position: relative;
    /* height: 3.3rem; */
    /* border: 6px solid #bf1d2c; */
    padding: 0.2rem 0.3rem;
}
.aboutBox_Div_right :hover img{
    transform: scale(1.2);
}
.aboutBox_Div_right2 :hover img{
    transform: scale(1.2);
}

.aboutBox_Div_right2{
    flex: 1;
    position: relative;
    /* height: 3.3rem; */
    /* border: 6px solid #bf1d2c; */
    padding: 0.2rem 0.3rem;
}

.aboutBox_Div_left2{
    width: 45%;
    margin-left: 0.7rem;
    /* margin-top: -1rem; */
}

.abouImg{
    width: 100%;
    margin-top: -0.7rem;
    position: relative;
    z-index: 11;
    overflow: hidden;
}
.abouImg img{
    transition: all 1s;
}
.aboutBox_Div_right_tag{
    font-size: 0.18rem;
    color: #666666;
    background-color: #ffffff;
    position: absolute;
    right: -0.6rem;
    top: -17.4%;
    transform: rotateZ(90deg);
    z-index: 10;
    width: 1.2rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
}
.aboutBox_Div_right_tag2{
    font-size: 0.18rem;
    color: #666666;
    background-color: #f5f5f5;
    position: absolute;
    left: -0.8rem;
    top: -10%;
    transform: rotateZ(90deg);
    width: 1.6rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    z-index: 10;
}

.aboutBox_Div3{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 0.8rem;
}
.aboutBox_Div3_li{
    width: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.aboutBox_Div3_li img{
    width: 100%;
    vertical-align: middle;
}

.fumt{
    margin-top: -0.85rem;
}
.aboutBox_Div3_hoverDiv{
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(191, 29, 44, 0.8);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 1s;
}
.aboutBox_Div3_hoverTitle{
    font-size: 0.3rem;
    text-align: center;
    margin-bottom: 0.25rem;
    width: 100%;
    font-weight: bold;
}

.aboutBox_Div3_hoverText{
    font-size: 0.16rem;
    line-height: 0.35rem;
    width: 80%;
    margin: 0 auto;
}
.aboutBox_Div3_li_rightDiv{
    position: absolute;
    font-size: 0.3rem;
    color: #ffffff;
    right: 0.4rem;
    top: 0.4rem;
    transition: all 0.5s;
    font-weight: bold;
}
.aboutBox_Div3_li_rightDivL{
    right: unset;
    left: 0.4rem;
}
.aboutBox_Div3_li:hover .aboutBox_Div3_hoverDiv{
    left: 0;
}
.aboutBox_Div3_li:hover .aboutBox_Div3_li_rightDiv{
    opacity: 0;
}
.aboutBox_Div4{
  padding: 0 1rem;
}
.aboutBox_Div4_title{
    font-size: 0.3rem;
    color: #4c4c4c;
    text-align: center;
    margin-bottom: 0.4rem;
}
.aboutBox_Div4_text{
    font-size: 0.16rem;
    color: #4c4c4c;
    line-height: 0.32rem;
}
.aboutBox_Div4_fzgh{
   
    margin: 0.66rem  0 0;
    position: relative;

}
.aboutBox_Div4_fzgh img{
   width: 70%;
}
.aboutBox_Div4_fzghDiv{
    position: absolute;
    font-size: 0.18rem;
    color: #333333;
    text-align: right;
    font-weight: bold;
    line-height: 0.3rem;
}
.aboutBox_Div4_fzghDiv1{
    top: 10%;
    right: 60%;
}
.aboutBox_Div4_fzghDiv2{
    left:74%;
    top: 38%;
    text-align: left;
}
.aboutBox_Div4_fzghDiv3{
    left:67%;
    top: -5%;
    text-align: left;
}
.aboutBox_Div5{
    display: flex;
    align-content: center;
}
.about_Div5_Div{
    width: 44.5%;
    padding: 0.2rem 0.3rem 0;
}
.about_Div5_left{
    background-color: #f7f7f7;
    margin-right: 3%;
}
.about_Div5_right{
    background-color: #f7f7f7;
}
.about_Div5_left_NavList{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.about_Div5_left_title{
    font-size: 0.3rem;
    color: #333333;
    padding: 0.15rem 0;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    font-weight: bold;
}
.about1 .navActive{
    border-bottom: 4px solid #a21003;
}
.about1  .swiper-Box{
    height: 4.55rem;
    padding:  0.3rem 0 0.5rem;
}
.about1 .swiperDiv{
    display: flex;
    flex-wrap: wrap;
 
    margin-right: -0.2rem;
}
.about1 .swiperDiv_li{
    width: 30%;
    height: 0.95rem;
    background-color: #ffffff;
    margin-right: 3.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2rem;
}
.about1 .swiperDiv_li img{
    /* height: 100%; */
    height: 100%;
    max-height: 0.74rem;
}
.about1 .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 20px;
}
.about1 .swiper-pagination-bullet{
    border: 4px solid #a21003;    
    background-color: #a21003;
    opacity: 1;
    /* border-radius: 100%; */
}
.about1 .swiper-pagination-bullet-active{
    background-color: #ffffff;
}


/*当浏览器尺寸小于1660px时候的代码：*/ 
@media  screen and (max-width: 1680px){

}
@media  screen and (max-width: 1560px){
    .about1 .swiperDiv_li {
        width: 46.7%;
    }
   .about1  .swiper-Box{
        height: 6.9rem;
    }
}
@media  screen and (max-width: 1400px){
     
}
@media screen and (max-width: 1200px){
  
}


/* 移动端 */ 
@media screen and (max-width: 1024px){
   .aboutBox_Div{
       flex-wrap: wrap;
   }
   .aboutBox_Div_left{
       width: 100%;
       margin-bottom: 1.3rem;
   }
   .aboutBox_Div2{
       flex-direction: column-reverse;
   }
   .aboutBox_Div_left2{
       width: 100%;
       margin-bottom: 1.3rem;
       margin-left: 0;
   }
   .aboutBox_Div4{
       padding: 0 0.2rem;
   }
   .aboutBox_Div4_fzghDiv1 {
       top: 4%;
   }
   .aboutBox_Div4_fzghDiv3{
       top: -7%;
   }
   .aboutBox_Div4_fzghDiv2{
       top: 34%;
   }
   .aboutBox_Div5{
       flex-wrap: wrap;
   }
   .about_Div5_Div {
       margin-right: 0;
       width: 100%;
       margin-bottom: 0.4rem;
   }
   .swiperDiv_li {
       width: 30%;
       margin-right: 3.3%;
   }
   .swiper-Box{
       height: 4.55rem;
    }
}
/* 移动端 */ 
@media screen and (max-width:768px){
   .about_Div5_Div {
       width: 91%;
   }
   .aboutBox_Div_left_text{
       font-size: 12px;
       line-height: 24px;
   }
   .aboutBox_Div_left {
       margin-right: 0;
   }
   .aboutBox_Div4_text{
       font-size: 12px;
       line-height: 24px;
   }
  
}
@media screen and (max-width: 414px){
   .about1  .swiperDiv_li {
        width: 46.7%;
    }
   .about1  .swiper-Box{
        height: 7.1rem;
    }

    .aboutBox_Div4_fzghDiv{
        font-size: 12px;
    }
   .aboutBox_Div4_fzghDiv1 {
       top: -8%;
   }
   .aboutBox_Div4_fzghDiv3{
       top: -11%;
   }
   .aboutBox_Div4_fzghDiv2{
       top: 34%;
   }
   .about1 .swiper-pagination-bullet{
       border: 3px solid #a21003;
   }
   .aboutBox{
       padding-top: .5rem;
   }
   .aboutBox_title{
       margin-bottom: .4rem;
   }
}
</style>