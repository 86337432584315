<template>
  <div class="about" >
      <banner :bannerIndex="0"/>
        <div class="bannerDiv">
              <div class="bannerBtn" :class="navActive==0?'bannerActive':''" @click="NavSwitch(0)">公司介绍</div>
              <div class="bannerBtn2" :class="navActive==1?'bannerActive':''" @click="NavSwitch(1)">组织架构</div>
          </div>
      <about1 :content='content' v-if="navActive==0"/>
      <about2 :content='content.content8'  v-if="navActive==1"/>
  </div>
</template>

<script>
import banner from "../../components/home/banner.vue";
import about1 from "./about1.vue";
import about2 from "./about2.vue";
export default {
  name: "about",
  components: {
      banner,
      about1,
      about2
  },
  data() {
    return {
      navActive:this.$route.query.navctiveIndex || 0,
      content:'',
    };
  },
 watch: {
    $route(to, from) {
      console.log('to',to)
      if(to.query.navctiveIndex){
        this.navActive = to.query.navctiveIndex;
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    console.log('to',to)
    this.navActive = to.params.id
    next()
  },
  created(){
    if(this.$route.path.split('about/')[1]){
      this.navActive=this.$route.path.split('about/')[1]
    }
     
      this.about()
  },
  methods:{
      MonitorScroll(){
          console.log(1)
      },
      NavSwitch(index){
          this.navActive=index
      },
       //分割数据 数据   数量
      group(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while(index < array.length) {
            newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
      },
      about() {
        let that = this;
        let data = {};
        that.$api.about(data).then((res) => {
            if (res.status == 1) {
                that.content=res.data;
                 for(let i=0;i<that.content.content7.length;i++){
                    that.content.content7[i].multiple_pic=that.group(that.content.content7[i].multiple_pic, 12);
                }
            } else {
                layer.msg(res.msg);
            }
        });
      },
  },
};
</script>
<style scoped>
.bannerBox{
    width: 100%;
    height: 100vh;
    position: relative;
}
.aboutImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.bannerDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -1.25rem;
    margin-bottom: 0.4rem;
    position: relative;
    z-index: 99;
}
.bannerBtn{
    width: 2.95rem;
    height: 0.78rem;
    text-align: center;
    line-height: 0.78rem;
    font-size: 0.24rem;
    color: #333333;
    background-color: rgba(255, 255, 255, 0.9);
    margin-right: 0.75rem;
    transition:background .5s ease-in-out;
    cursor: pointer;
}
.bannerBtn2{
    width: 2.95rem;
    height: 0.78rem;
    text-align: center;
    line-height: 0.78rem;
    font-size: 0.24rem;
    color: #333333;
    background-color: rgba(255, 255, 255, 0.9);
    transition:background .5s ease-in-out;
    cursor: pointer;
}
.bannerBtn2:hover,.bannerBtn:hover{
  background: rgba(162, 16, 3, .85);
  color: #fff;
}
.bannerActive{
    background-color:  rgba(162, 16, 3, .85);
    color: #ffffff;
}
@media (max-width: 768px) {
    .bannerDiv{
        display: none;
    }
}
 
</style>